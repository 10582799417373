body {
  background: #000000;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  padding: 0;
}

#message {
  max-width: 360px;
  margin: 100px auto 16px;
  padding: 32px 24px 16px;
  border-radius: 3px;
}

#message p {
  line-height: 140%;
  margin: 16px 0 24px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}

#load {
  color: rgba(0, 0, 0, 0.4);
  text-align: center;
  font-size: 13px;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
  margin-block: 0;
}

/* アニメーション */

@keyframes blur-effect {
  0% {
    filter: blur(0px);
  }

  40% {
    filter: blur(1px);
  }

  45% {
    filter: blur(0px);
  }

  55% {
    filter: blur(0px);
  }

  74% {
    filter: blur(1px);
  }

  to {
    filter: blur(0);
  }
}

@keyframes flash-effect {
  0% {
    opacity: 1;
  }

  10% {
    opacity: 1;
  }

  10.1% {
    opacity: 0;
  }

  11% {
    opacity: 0;
  }

  11.1% {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}

/* クラス名に意味を持たせたスタイル */

.not-found-header {
  color: #fff;
  text-align: center;
}

@media (max-width: 700px) {
  .not-found-header {
    font-size: 35px;
    font-weight: 700;
    line-height: 1.2;
  }
}

@media (min-width: 900px) {
  .not-found-header {
    font-size: 35px;
    font-weight: 700;
    line-height: 1.055555555;
  }
}

.word-row {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: .2em;
}

.letter {
  text-shadow: 0 0 0.2em;
}

.letter-blur {
  animation: blur-effect 6s linear infinite both;
}

.letter-flash {
  animation: flash-effect 1.3s linear infinite both;
}

/* 各文字の点滅タイミングをずらす */
.word-row .letter-flash:nth-child(1) {
  animation-delay: 0s;
}

.word-row .letter-flash:nth-child(2) {
  animation-delay: 0.4s;
}

.word-row .letter-flash:nth-child(3) {
  animation-delay: 0.2s;
}

.word-row .letter-flash:nth-child(4) {
  animation-delay: 0.1s;
}